/**
 * @file
 * @author Alwyn Tan
 */

import React from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import Ticker from 'react-ticker'
import Particles from 'react-particles-js'
import Img from 'gatsby-image'
import shuffle from 'lodash/shuffle'

import Layout from '../components/layout'
import ButtonV2 from '../components/ButtonV2'
import particlesParams from '../constants/founderlinkParticlesParams.json'
import Footer from '../components/footer'

const FullScreenContainer = styled.div`
  width: 100%;
  padding-top: 75px;
  display: flex;
  justify-content: center;
`

const Container = styled.div`
  max-width: 1200px;
  width: 100%;
`

const TitleRow = styled.section`
  width: 100%;
  height: 500px;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  > * {
    flex: 1;
  }
`

const TitleBox = styled.div`
  > *:not(:first-child) {
    margin-top: 30px;
  }

  padding: 0 30px;

  @media only screen and (max-width: 768px) {
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const ParticlesContainer = styled.div`
  mask-image: radial-gradient(
    50% 50% at 50% 50%,
    #ffffff 0%,
    #ffffff 70%,
    transparent 99%,
    transparent 100%
  );

  height: 100%;

  > * {
    height: 100%;
  }

  @media only screen and (max-width: 768px) {
    opacity: 0.5;
  }
`

const QuoteRow = styled.section`
  margin: 60px 0;
  position: relative;
  text-align: right;
  padding: 60px 30px;
  overflow: hidden;
`

const HowRow = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 100px;
`

const RowBackground = styled.div`
  position: absolute;
  background-color: #f7f7fa;
  top: 0;
  left: -9999999px;
  right: -9999999px;
  bottom: 0;
  z-index: -1;
`

const HowCard = styled.div`
  padding: 13px 20px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  margin-bottom: 20px;
`

const Founderlink = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          sourceInstanceName: { eq: "images" }
          relativeDirectory: { eq: "founderlink" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fixed(height: 40, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `)
  const companyImgs = shuffle(data.allFile.edges)

  const handleDialInClick = () => {
    navigate('/app/calls/founder')
  }

  return (
    <Layout>
      <FullScreenContainer>
        <Container>
          <TitleRow>
            <TitleBox>
              <h2>Founder Link</h2>
              <p>
                1:1 <strong>personalized calls</strong> to meet other founders
              </p>
              <ButtonV2 onClick={handleDialInClick}>Sign Up</ButtonV2>
            </TitleBox>
            <ParticlesContainer>
              <Particles params={particlesParams} />
            </ParticlesContainer>
          </TitleRow>
          <section>
            <p style={{ padding: '0 0 30px 30px' }}>
              Some of the founders you’ll meet:
            </p>
            <Ticker height={50}>
              {({ index }) => (
                <div style={{ padding: '0 20px' }}>
                  <Img
                    fixed={
                      companyImgs[index % companyImgs.length].node
                        .childImageSharp.fixed
                    }
                  />
                </div>
              )}
            </Ticker>
          </section>
          <QuoteRow>
            <RowBackground />
            <h2 style={{ paddingBottom: 10 }}>
              <i>
                &apos;Emails get reactions. Phone calls start
                conversations&apos;
              </i>
            </h2>
            <p>- Simon Sineck</p>
          </QuoteRow>
          <HowRow>
            <h2 style={{ marginBottom: 40 }}>How it Works</h2>
            <HowCard>
              <p>1. Apply to join</p>
            </HowCard>
            <HowCard>
              <p>2. Sign up for 15 minute weekly calls</p>
            </HowCard>
            <HowCard style={{ marginBottom: 40 }}>
              <p>3. Sit back and let us call you</p>
            </HowCard>
            <ButtonV2 onClick={handleDialInClick}>Sign Up</ButtonV2>
          </HowRow>
          <Footer />
        </Container>
      </FullScreenContainer>
    </Layout>
  )
}

Founderlink.propTypes = {}

export default Founderlink
